import axios, { AxiosError, AxiosResponse } from "axios";

import { assertNever } from "../util/typeAssertions";

export interface ErrorResponse {
  error: string;
}

export enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export class ErrorMetadatable extends Error {
  metadata: Record<string, any> | undefined;

  constructor(message: string, metadata?: Record<string, any>) {
    super(message);
    this.name = this.constructor.name;
    this.metadata = metadata;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export async function callEndpoint<T>(
  method: Method,
  endpoint: string,
  params?: Record<string, any>,
): Promise<T> {
  try {
    const response = await rawCallEndpoint<T>(method, endpoint, params);
    return response.data;
  } catch (error) {
    const { message, info } = getErrorMessage(error as AxiosError<any>);
    throw new ErrorMetadatable(message, info);
  }
}

function getErrorMessage(error: AxiosError): {
  message: string;
  info?: Record<string, any>;
} {
  if (!error.isAxiosError) {
    return { message: "Unknown error." };
  }
  const { response } = error;
  if (!response) {
    return { message: "Could not reach server." };
  }
  return {
    message: response.data?.error ?? "Server error.",
    info: response.data?.info,
  };
}

function rawCallEndpoint<T>(
  method: Method,
  endpoint: string,
  params?: Record<string, any>,
): Promise<AxiosResponse<T>> {
  switch (method) {
    case Method.GET:
      return axios.get(endpoint, { params });
    case Method.POST:
      return axios.post(endpoint, params);
    case Method.PUT:
      return axios.put(endpoint, params);
    case Method.DELETE:
      return axios.delete(endpoint, { params });
    default:
      return assertNever(method);
  }
}

export function configureAxios(): void {
  axios.defaults.headers.common.Accept = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
}

import { getRecaptchaToken } from "../util/captchaFrontend";
import { callEndpoint, Method } from "./axios";

export interface CommonLoginParams {
  redirectUrl?: string;
  inviteToken?: string;
  referral?: string;
}

export interface LoginParams extends CommonLoginParams {
  email: string;
  password: string;
}

export interface GoogleLoginParams extends CommonLoginParams {
  code: string;
  sessionId: string;
  hCaptchaToken: string;
  mfvRequestParams?: MFVChallengeParams;
}

export enum MFVTwilioChannelTypes {
  Sms = "sms",
  Call = "call",
}

export interface MFVChallengeParams {
  mfvChallengeCode: string;
  email: string;
  userMobileNumber?: string;
  mfvChannel?: MFVTwilioChannelTypes;
  verificationCode?: string;
}

export interface CheckSSOEmailParams extends CommonLoginParams {
  email: string;
}

export interface LoginResult {
  redirectTo: string;
  userId?: string;
}

export interface CheckSSOEmailResult {
  redirectTo: string;
}

export function login(params: LoginParams): Promise<LoginResult> {
  return callEndpoint(Method.POST, "/api/login", params);
}

export function googleLogin(params: GoogleLoginParams): Promise<LoginResult> {
  return callEndpoint(Method.POST, "/api/google-login", params);
}

export function googleMFVSignup(
  params: GoogleLoginParams,
): Promise<LoginResult> {
  return callEndpoint(Method.POST, "/api/mfv-google-signup", params);
}

export function checkSSOEmail(
  params: CheckSSOEmailParams,
): Promise<CheckSSOEmailResult> {
  return callEndpoint(Method.POST, "/api/check-sso-email", params);
}

export interface CreateUserParams {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  redirectUrl?: string;
  inviteToken?: string;
  sessionId?: string;
  hCaptchaToken: string;
  referral?: string;
  mfvRequestParams?: MFVChallengeParams;
}

export async function createUser(
  params: CreateUserParams,
): Promise<Partial<LoginResult>> {
  const recaptchaToken = await getRecaptchaToken();
  return callEndpoint(Method.POST, "/api/user", { ...params, recaptchaToken });
}

export async function sendMfvCode(params: MFVChallengeParams): Promise<void> {
  return callEndpoint(Method.POST, "/api/send-verification-code", params);
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isStaff: boolean;
  hasPassword: boolean;
}

export async function getUser(): Promise<User | undefined> {
  return (await callEndpoint(Method.GET, "/api/session-user")) || undefined;
}

export interface InviteInfo {
  email: string;
  serviceName: string;
}

export function getInviteInfo(inviteToken: string): Promise<InviteInfo> {
  return callEndpoint(Method.GET, "/api/invite", { inviteToken });
}

export function confirmInviteFromSession(
  inviteToken: string,
): Promise<LoginResult> {
  return callEndpoint(Method.POST, "/api/session-invite", { inviteToken });
}

export interface VerifyEmailParams {
  token: string;
}

export function verifyEmail(params: VerifyEmailParams): Promise<LoginResult> {
  return callEndpoint(Method.POST, "/api/verify-email", params);
}

export function canAutoverifyEmail(): Promise<boolean> {
  return callEndpoint(Method.GET, "/api/can-autoverify-email");
}

export interface RequestPasswordResetParams {
  email: string;
  redirectUrl?: string;
  inviteToken?: string;
}

export async function requestPasswordReset(
  params: RequestPasswordResetParams,
): Promise<void> {
  const recaptchaToken = await getRecaptchaToken();
  return callEndpoint(Method.POST, "/api/password-reset", {
    ...params,
    recaptchaToken,
  });
}

export interface UpdatePasswordParams {
  token: string;
  password: string;
}

export function updatePassword(
  params: UpdatePasswordParams,
): Promise<LoginResult> {
  return callEndpoint(Method.POST, "/api/password", params);
}

export interface ConfirmEmailChangeParams {
  token: string;
}

export interface ConfirmEmailChangeResult {
  redirectUrl: string;
}

export function confirmEmailChange(
  params: ConfirmEmailChangeParams,
): Promise<ConfirmEmailChangeResult> {
  return callEndpoint(Method.POST, "/api/confirm-email-change", params);
}

export function logout(): Promise<void> {
  return callEndpoint(Method.DELETE, "/api/logout");
}

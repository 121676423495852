export function getRecaptchaToken(): Promise<string> {
  const { grecaptcha, recaptchaSiteKey } = window as any;
  if (!grecaptcha || !recaptchaSiteKey) {
    return Promise.reject(new Error("reCAPTCHA not loaded."));
  }
  return new Promise((resolve, reject) => {
    grecaptcha.ready(() =>
      grecaptcha.execute(recaptchaSiteKey, { action: "submit" }).then(resolve),
    );
    setTimeout(
      () =>
        reject(
          new Error("Timed out while waiting for reCAPTCHA to become ready."),
        ),
      5000,
    );
  });
}
